<template>
    <div class="other">
        <h1 style="background-color: white">This is an other page</h1>
    </div>
</template>
<script>
export default {
    name: "OtherView"
}
</script>

<style lang="scss" scoped></style>