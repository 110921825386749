export const m = {
    home: 'Home',
    about: 'About',
    showCase: 'Show case',
    works: 'Works',
    other: 'Other',
    example: 'Example',
    who: 'Who am I?',
    languageSwitch: "Language switch",
    introduce: 'A houston-base designer and illustrator focusing on',
    work: 'Work with me',
    work_with_me: 'I enjoy being on the bleeding edge of technology and',
    areas: 'Areas I thrive in',
    area_of_expertise: {
        1: 'User-centered product design, from conception to implementation',
        2: 'Rapid prototyping, testing and developing ideas into fully fledged products',
        3: 'Designing visual identity guidelines and design systems',
        4: 'Helping companies establish a lean scalable, repeatable, measurable design process',
    },
    more: 'Read more',
    show: 'THE SEMPLICE SHOWCASE',
    proudly: 'Proudly serving a niche community.',
    see: ' -> See inspiring portfo',
    week: {
        sun: '星期日',
        mon: '星期一',
        tues: '星期二',
        wed: '星期三',
        thur: '星期四',
        fri: '星期五',
        sat: '星期六'
    },
    menu: '菜单',
    life: {
        1: 'In a world where no one knows what tomorrow will bring',
        2: 'The only thing that can save people from depression and madness is simple and original writing.',
    },
    lifeMotto: 'Life is wonderful because of design',
    discover: 'DISCOVER',
    contact: 'CAOTACT',
    elsewhere: 'ELSEWHERE',
    personal: 'Personal introduction',
    nameTitle: "Name:",
    name: 'Chang na xin',
    ageTitle: 'age:',
    age: '90',
    constellationTitle: 'constellation:',
    constellation: 'Pisces',
    hobbyTitle: 'hobby:',
    hobby: 'Hobby:reading、drawing、running、travel',
    experience: 'Work experience',
    foot: 'Footi is an in-house Little Thunder passion project.   Built with a Jamstack methodology, all content is pre-rendered at build time and served as static HTML/',
    project: 'A selection of current and past projects',
    website: '"As soon as we knew we had the budget to do a new company website, we reached out to DJNR Interactive. We have been working with them for several years and knew the quality of work they do. Our latest project before Dieste.com was a mock up travel agency site for a bookstore that won several awards and got worldwide recognition. The goal for our site was to have our work be the main selling point of our company and we worked closely with DJNR´s team to achieve the best solution. They brought a lot of input onto',
    Director: 'Roberto Hernández, Director Social & Digital Strategy',
    view: 'VIEW PROJECT >',
}
