<template>
  <div class="col">
    <HeaderComponent :menuName="'home'" />
    <div class="top_bg"></div>
    <div class="row_center bg_text_view">
      <marquee class="bg_text" behavior="scroll" scrollamount="10">
        If you can design one thing, you can design every thing.
      </marquee>
    </div>
    <div class="main">
      <div class="introduce">
        <img class="art_img" src="../assets/icon_head.png" alt="logo" />
        <div class="col_center left">
          <h1>{{ t("who") }}</h1>
          <p style="z-index: 999;">{{ t("introduce") }}</p>
        </div>
      </div>
      <div class="row_start work">
        <img class="mine_img" src="../assets/head.png" />
        <div class="work_with_me">
          <h1>{{ t("work") }}</h1>
          <p>{{ t("work_with_me") }}</p>
          <h2>{{ t("areas") }}</h2>
          <li v-for="index in 4" v-bind:key="'areas' + index">
            {{ ti("area_of_expertise", index) }}
          </li>
          <router-link to="/about" class="show_more">{{
            t("more")
          }}</router-link>
        </div>
      </div>
      <div class="line_view">
        <h2>{{ t("show") }}</h2>
        <div class="h_line"></div>
      </div>
      <!-- <h1 style="margin-top: 60px">{{ t("proudly") }}</h1> -->
      <h1 style="margin-top: 40px">敬请期待！</h1>
      <router-link class="show_all" to="/showCase"><img class="right_arrow" src="../assets/right_arrow_blue.png" alt="">{{
        t("see")
      }}</router-link>
      <!-- <div class="row_center scroll" v-for="index in 3" :class="'scroll' + index" v-bind:key="'img' + index">
        <img class="img" src="../assets/auto.png">
        <img class="img" src="../assets/auto.png">
        <img class="img" src="../assets/auto.png">
      </div> -->
    </div>
    <FooterComponent />
  </div>
</template>

<script>
import HeaderComponent from "@/components/HeaderComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";
export default {
  name: "HomeView",
  components: {
    FooterComponent,
    HeaderComponent,
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "../styles/variable";

h1 {
  margin: 0;
  text-align: left;
  font-size: 74px;
  white-space: nowrap;
  overflow: hidden;
}

hr {
  border: 0.5px solid gray;
  margin-top: 52px;
  width: 70%;
}

li {
  margin-top: 25px;
  font-size: 12px;
}

a {
  color: $whiteColor;
  text-decoration: none;
}

.top_bg {
  position: absolute;
  top: 150px;
  width: 100%;
  height: 650px;
  background: linear-gradient(#151719, #232323);
}

.bg_text_view {
  position: absolute;
  top: 680px;
  width: 100%;
  z-index: 0;

  .bg_text {
    color: #4b4b4b;
    font-weight: bold;
    font-size: 90px;
    opacity: 0.35;
  }
}

.main {
  flex-direction: column;
  color: $whiteColor;
  line-height: 1.75;

  .introduce {
    position: relative;
    height: 900px;

    .left {
      position: absolute;
      align-items: flex-start;

      h1 {
        margin-top: 178px;
      }

      p {
        text-align: left;
        font-size: 32px;
        margin-top: 45px;
        width: 75%;
      }
    }

    .art_img {
      position: absolute;
      top: 92px;
      right: 0;
      width: 588px;
      height: 783px;
    }
  }

  .work {
    margin-top: 150px;

    .mine_img {
      width: 320px;
      height: 320px;
      margin-right: 100px;
    }

    .work_with_me {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: left;

      h2 {
        font-size: 28px;
        margin: 15px 0 -5px;
      }

      p,
      li {
        font-size: 22px;
      }

      .show_more {
        margin-top: 80px;
        border: 1px solid $whiteColor;
        padding: 12px 30px;
        font-size: 22px;
        border-radius: 5px;
      }
    }
  }

  .line_view {
    display: flex;
    align-items: center;
    margin-top: 150px;

    h2 {
      width: 140px;
      text-align: left;
      margin: 0;
    }

    .h_line {
      background-color: #4c4c4c;
    }
  }

  .show_all {
    display: inline-block;
    width: 100%;
    margin-top: 55px;
    text-align: left;
    color: $blueColor;
    font-size: 28px;

    .right_arrow {
      width: 20px;
      height: 12px;
      padding-bottom: 3px;
      margin-right: 15px;
    }
  }
}

.img {
  height: 250px;
  margin-right: 40px;
}
</style>