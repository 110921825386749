<template>
  <div class="FooterComponent">
    <div class="slogan_container">
      <h2 class="slogan">{{ t("lifeMotto") }}</h2>
    </div>
    <div class="col_start left">
      <h2>{{ t("discover") }}</h2>
      <ul>
        <li v-for="view in views" v-bind:key="view">
          <router-link :to="'/' + (view != 'home' ? view : '')">{{
            t(view)
          }}</router-link>
        </li>
      </ul>
    </div>
    <div class="col_start menus">
      <h2>{{ t("contact") }}</h2>
      <ul>
        <li>17611792362</li>
        <li>
          <a
            href="mailto:sample@fly63.com?subject=test&cc=sample@hotmail.com&subject=主题&body=内容">changnaxin1991@gmail.com</a>
        </li>
      </ul>
    </div>
    <div class="col_center other">
      <h2>{{ t("elsewhere") }}</h2>
      <div class="row_center social">
        <div class="col_center show_qr" style="justify-content: start" v-for="index in 3" v-bind:key="index"
          @mouseover="mouseover(index)" @mouseleave="mouseleave(index)">
          <img class="icon" :src="iconWithIndex(index)" />
          <img class="qr" :src="qrWithIndex(index)" v-show="qrShow[index - 1]" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterComponent",
  data() {
    return {
      views: ["home", "about", "works", "showCase"],
      qrShow: [false, false, false],
    };
  },
  methods: {
    iconWithIndex(index) {
      let icons = ["icon_wechat", "icon_qq", "icon_sina"];
      return require('@/assets/' + icons[index - 1] + ".png");
    },
    qrWithIndex(index) {
      let icons = ["qr_weixin", "qr_qq", "qr_sina"];
      return require('@/assets/' + icons[index - 1] + ".png");
    },
    mouseover(index) {
      this.$set(this.qrShow, index - 1, true);
    },
    mouseleave(index) {
      this.$set(this.qrShow, index - 1, false);
    },
  },
};
</script>

<style scoped>
.FooterComponent {
  position: static;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  box-sizing: border-box;
  padding: 60px 85px 0 130px;
  background-color: #0a0b0c;
  height: 350px;
  bottom: 0;
  line-height: 1.4;
}

.main {
  justify-content: space-between;
}

.slogan_container {
  flex: 1;
}

.main .slogan {
  min-width: 400px;
}

.left {
  font-size: 18px;
  width: 230px;
}

.menus {
  width: 400px;
}

.icon {
  height: 35px;
  width: 35px;
}

h2 {
  margin: 0;
  font-size: 22px;
  color: white;
  text-align: left;
}

ul {
  padding: 0;
  margin-top: 30px;
}

li {
  text-align: left;
  list-style: none;
  font-size: 18px;
  color: white;
  margin: 15px 0;
}

a {
  color: white;
  text-decoration: none;
}

.other {
  width: 240px;
  align-items: flex-start;
}

.social {
  width: 100%;
  margin-top: 30px;
  margin-left: -25px;
}

.show_qr {
  flex: 1;
  height: 100px;
}

.qr {
  width: 80px;
  margin-top: 5px;
}
</style>