<template>
  <div class="ShowCaseView">
    <HeaderComponent :menuName="'showCase'" />
    <el-carousel class="carousel" direction="vertical" :autoplay="false" :interval="2000" trigger="click">
      <el-carousel-item v-for="item in 10" :key="item" :label="'2022年' + item + '月'">
        <h2>showcase</h2>
        <h3>A selection of current and past projects</h3>
        <div class="main rank">
          <div class="left col_center">
            <h1>CHOOSE AUTO-PC</h1>
            <img class="mine_img" src="../assets/auto.png">
          </div>
          <div class="right col_center">
            <h4>CLIENT: Dieste</h4>
            <h4>AGENACY: DJNR</h4>
            <p style="color: #4B4B4B">"As soon as we knew we had the budget to do a new company website, we reached out to
              DJNR Interactive. We have been working with them for several years and knew the quality of work they do. Our
              latest project before Dieste.com was a mock up travel agency site for a bookstore that won several awards
              and got worldwide recognition. The goal for our site was to have our work be the main selling point of our
              company and we worked closely with DJNR´s team to achieve the best solution. They brought a lot of input
              onto what things would enhance what we had in mind. We needed an easy to update site and DJNR built a custom
              CMS that allow us to do changes quickly. We are really proud of the final product."</p>
            <h4 class="left" style="color: #4B4B4B"> Roberto Hernández, Director Social & Digital Strategy </h4>
            <p>VIEW PROJECT ></p>
            <div>
              <el-tag type="info" color="white" size="mini">HTML</el-tag>
              <el-tag type="info" color="white" size="mini">JAVASCRIPT</el-tag>
              <el-tag type="info" color="white" size="mini">CSS3</el-tag>
              <el-tag type="info" color="white" size="mini">SCROLL MAGIC</el-tag>
            </div>
            <div>
              <el-tag type="info" color="white" size="mini">NETLIFY FORWS</el-tag>
            </div>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import HeaderComponent from '@/components/HeaderComponent.vue';
export default {
  components: { HeaderComponent },
  name: "ShowCaseView",
  data() {
    return {

    };
  },
  methods: {
    scrollMethod() {
      console.log('滚动')
      console.log(document.documentElement.scrollTop)
    }
  },
  mounted() {
    // 挂载时，添加监听滚动事件，然后用 scrollMethod 方法进行处理
    window.addEventListener('scroll', this.scrollMethod)
  },
  destroyed() {
    // 销毁时，删除监听滚动事件，否则其他.vue文件也会受影响
    window.removeEventListener('scroll', this.scrollMethod)
  }

};

</script>

<style lang="scss" scoped>
.ShowCaseView {
  height: 100vh;
  display: flex;
  flex-direction: column;
  color: white;

  h1 {
    color: crimson;
    font-size: 50px;
    margin: 0 0 50px;
  }

  h2,
  h3 {
    margin-left: 12.5%;
  }

  h2 {
    margin-top: 50px;
    text-align: left;
  }

  h3 {
    font-size: 16px;
    text-align: left;
  }

  .carousel {
    flex: 1;

    /deep/ .el-carousel__container {
      height: 100%;
    }

    .rank {
      display: flex;
      align-items: stretch;
      justify-content: space-around;
      margin-top: 100px;
      background-color: #151719;

      .left {
        z-index: 100;
        padding-bottom: 3%;
        align-items: start;

        .mine_img {
          margin-right: 100px;
        }
      }

      .right {
        align-items: start;
        font-size: 12px;
      }
    }
  }
}

/deep/ .el-carousel__indicators {
  top: 50%;
  left: initial;
  right: 50px;
  width: 80px !important;
  transform: translateY(-50%) !important;
}

/deep/ .el-carousel__button {
  padding: 0 !important;
  width: 80px !important;
  height: 20px !important;
}

/deep/ .el-tag {
  margin: 0 5px 5px 0;
}
</style>