<template>
  <div class="HeaderComponent">
    <div class="main" style="justify-content: space-between;">
      <img class="logo" src="../assets/logo.png" alt="logo">
      <nav class="row_center">
        <router-link :class="menuName == menu || menuName == '' ? 'border' : ''" v-for="menu in menus" :key="menu"
          :to="menu == 'home' ? '/' : menu">
          {{ t(menu) }}
        </router-link>

        <el-select v-if="false" popper-class="selectFrom" v-model="value" :placeholder="placeholder()" size="small"
          :popper-append-to-body="false">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </nav>
    </div>
  </div>
</template>

<script>

export default {
  name: 'HeaderComponent',
  props: {
    msg: String,
    menuName: String
  },
  data() {
    return {
      menus: [
        "home",
        "about",
        "works",
        "showCase",
        // 'other',
        // 'example'
      ],
      lang: "en",
      options: [{
        value: 'Chinese',
        label: '中文'
      }, {
        value: 'English',
        label: 'English'
      }],
      value: ''
    }
  },
  created() {
    localStorage.setItem("locale", "zh")
    this.$i18n.locale = 'zh';
  },
  watch: {
    value: function (newValue, oldValue) {
      console.log(newValue + "-" + oldValue);
      if (newValue === 'English') {
        this.lang = 'en';
        localStorage.setItem("locale", this.lang)
        this.$i18n.locale = this.lang;
      } else {
        this.lang = "zh";
        localStorage.setItem("locale", this.lang)
        this.$i18n.locale = this.lang;
      }
    }
  },
  methods: {
    switchMenu(menu) {
      console.log("a", menu)
    },
    placeholder() {
      let locale = localStorage.getItem("locale")
      return locale === "en" ? "English" : "中文"
    }
  }
}
</script>

<style scoped>
.HeaderComponent {
  width: 100%;
  height: 126px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #0A0B0C;
}

.main {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.el-select {
  width: 100px !important;
  margin-left: 20px !important;
}

/deep/ .el-select,
/deep/ .el-input,
/deep/ .is-focus,
/deep/ .el-input__inner {
  background: none;
  border-color: white !important;
}

/deep/ .el-popper {
  background: none !important;
}

/deep/ .el-select-dropdown__item {
  color: white;
}

/deep/ .el-select-dropdown__item.hover {
  /*color: black;*/
  background-color: dimgray;
}

.logo {
  height: 24px;
  width: 24px;
}

h3 {
  margin: 40px 0 0;
}

router-link {
  margin: 10px;
  font-size: 16px;
}

nav a {
  color: #ffffff;
  margin: 0 30px;
  padding: 10px 0;
  text-decoration: none;
}

.border {
  font-size: 18px;
  border-bottom: 2px solid white;
}
</style>
