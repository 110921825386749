<template>
  <div id="app">
    <router-view></router-view>
    <el-backtop :visibility-height="1000" :bottom="100" :right="80" />
  </div>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
      showHeader: true
    }
  }
}
</script>

<style scoped>
@import "../static/css/normalize.css";
@import "../static/css/global.css";

.el-backtop {
  background-color: transparent;
  background: url("./assets/back_top.png");
  background-size: 30px;
  width: 30px;
  height: 30px;
}

.el-backtop:hover {
  background: url("./assets/back_top.png");
  background-size: 30px;
}

.el-backtop :nth-child(1) {
  display: none !important;
}
</style>
