export const m = {
    home: '主页',
    about: '关于',
    showCase: '项目展示',
    works: '作品集',
    other: '其它',
    example: '示例',
    who: '我是谁',
    languageSwitch: "Switch language",
    introduce: '北京的90后UI设计师，专注于APP设计、网页设计、和交互设计。力争做一名全链路设计师。',
    work: '要一起工作吗？',
    work_with_me: '目前已离职，正在寻找一份UI设计工作。工作中擅长解决问题，工作贯穿概念/策略，设计到执行。实例项目丰富，喜欢探寻最新的设计理念，如果你需要一个有自己独特想法的合作伙伴，我想我们可以谈谈吧…',
    areas: '我擅长',
    area_of_expertise: {
        1: '以用户为中心的产品设计，从概念到实施',
        2: '关注设计、交互对产品带来的影响，善于理性分析场景、数据并服务于产品。',
        3: '设计规范制定和组件化设计，服务于开发。',
        4: '帮助公司建立一个精益的，可扩展的，可重复的，可衡量的设计过程。',

    },
    more: '查看更多',
    show: '项目展示',
    proudly: '部分作品案例轮播',
    see: '查看全部作品',
    week: {
        sun: '星期日',
        mon: '星期一',
        tues: '星期二',
        wed: '星期三',
        thur: '星期四',
        fri: '星期五',
        sat: '星期六'
    },
    menu: '菜单',
    life: {
        1: '在这个没人知道明天是什么样子的世界里，',
        2: '唯一能教人免于沮丧发狂的东西，就是朴实原始的作品。'
    },
    lifeMotto: 'Life is wonderful because of design',
    discover: '导航',
    contact: '联系方式',
    elsewhere: '社交账号',
    personal: '自我介绍',
    nameTitle: "姓名：",
    name: '常娜新',
    ageTitle: '年龄：',
    age: '90后',
    constellationTitle: '星座：',
    constellation: '双鱼',
    hobbyTitle: '爱好：',
    hobby: '读书、画画、跑步、旅行',
    experience: '工作经历',
    date: '2021.07-2023.07',
    date1:'2019.11-2021.05',
    date2:'2018.11-2019.10',
    date3:'2016.04-2018.10',
    company:'北京代码板块（科技）有限公司',
    company1:'新旗阳光（北京）科技有限公司',
    company2:'北京华购贸易有限公司',
    company3:'郑州天宇广告有限公司',
    foot: ' 参与产品讨论，与产品经理协作，从视觉设计和用户体验的角度提出建议与解决方案。定制UI设计规范、UI视觉输出，包括企业官网视觉设计、APP设计、B端产品设计、数据大屏视觉设计，支持完成产品的UI交互设计及优化。',
    foot1: ' 独立完成企业官网及APP端整体页面设计，产品原型设计。参与用户研究与需求讨论，对新产品的视觉定位以及品牌设计的探索。负责项目的设计评审、品质把控。与开发人员沟通，优化工作流程，提高工作效率。',
    foot2: ' 对公司的产品进行策划、原型绘制、界面的交互设计等; 对移动端/PC端产品进行设计和优化，高质量输出设计方案; 定期跟进用户反馈和产品风格探索，提出产品改进方案，优化产品用户体验;参与制定设计规范，保证产品一致性与可继承性; 独立制作产品宣传三维造型与渲染。',
    foot3: ' 制作企业名片、宣传册、折页、输出印刷文件；产品相关展会物料、展板、台签等；企业VI视觉识别系统设计。',
    project: '当前和过去项目的选择',
    website: '当我们知道我们有足够的预算来做一个新的公司网站时，我们就联系了DJNR Interactive。我们已经和他们合作了好几年，知道他们的工作质量。在Dieste.com之前，我们的最新项目是一家书店的模拟旅行社网站，该网站获得了多个奖项，并得到了全球的认可。我们网站的目标是让我们的工作成为我们公司的主要卖点，我们与DJNR的团队密切合作，以实现最佳解决方案。他们带来了大量的投入',
    Director: '罗伯特·埃尔南德斯,社交和数字战略总监',
    view: '查看项目 >',
}