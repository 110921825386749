<template>
  <div class="AboutView">
    <HeaderComponent :menuName="'about'" />
    <div class="bg_top">
      <img src="../assets/bg_top_about.png" />
    </div>
    <div class="main row_start">
      <div class="left_personal">
        <h1>{{ t("personal") }}</h1>
        <div class="img_cotainer">
          <img src="../assets/person.png" />
          <div></div>
        </div>
      </div>
      <div class="right_personal">
        <div class="border_cotainer">
          <div class="p_text">
            <p
              v-for="index in 2"
              v-bind:key="'life' + index"
              :class="index == 2 ? 'text_p' : ''"
            >
              {{ ti("life", index) }}
            </p>
          </div>
          <div class="line" style="height: 40px"></div>
          <div class="line" style="bottom: 0"></div>
          <div class="border_square"></div>
        </div>
        <div class="personal_info">
          <div
            class="span_container"
            v-for="(texts, index) in textArr"
            :key="index"
          >
            <span>{{ t(texts[0]) }}</span>
            <div>
              <span>{{ t(texts[1]) }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="block">
      <h2>{{ t("experience") }}</h2>
      <el-timeline>
        <el-timeline-item
          v-for="(info, index) in infos"
          :key="index"
          placement="top"
        >
          <el-card
            v-if="index % 2 == 0 && index != infos.length - 1"
            class="left_card"
          >
            <h3>{{ t(info[0]) }}</h3>
            <p style="color: gray">{{ t(info[1]) }}</p>
            <p style="color: gray">{{ t(info[2]) }}</p>
          </el-card>
          <el-card
            v-if="index % 2 == 1 && index != infos.length - 1"
            class="right_card"
          >
            <h3>{{ t(info[0]) }}</h3>
            <p style="color: gray">{{ t(info[1]) }}</p>
            <p style="color: gray">{{ t(info[2]) }}</p>
          </el-card>
        </el-timeline-item>
      </el-timeline>
    </div>
    <FooterComponent />
  </div>
</template>
<script>
import FooterComponent from "@/components/FooterComponent.vue";
import HeaderComponent from "@/components/HeaderComponent.vue";
export default {
  name: "AboutView",
  data() {
    return {
      textArr: [
        ["nameTitle", "name"],
        ["ageTitle", "age"],
        ["constellationTitle", "constellation"],
        ["hobbyTitle", "hobby"],
      ],
      infos: [
        ["date", "company", "foot"],
        ["date1", "company1", "foot1"],
        ["date2", "company2", "foot2"],
        ["date3", "company3", "foot3"],
        [],
      ],
    };
  },
  components: {
    HeaderComponent,
    FooterComponent,
  },
};
</script>
<style lang="scss" scoped>
p {
  color: white;
}

h1 {
  margin-right: 110px;
  margin-bottom: 30px;
  color: white;
  font-size: 20px;
}

h2 {
  margin-bottom: 20px;
  text-align-last: center;
  color: white;
}

.bg_top {
  position: absolute;

  img {
    width: 100%;
  }
}

.left_personal {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 200px;
}

.img_cotainer {
  position: relative;

  img,
  div {
    width: 200px;
    height: 200px;
  }
  div {
    border: 1px solid orange;
  }
  img {
    position: absolute;
    left: 15px;
    bottom: 15px;
  }
}

.right_personal {
  flex: 1;
  margin-top: 50px;
}

.border_cotainer {
  position: relative;
  .p_text {
    position: absolute;
    top: 30px;
  }
  .p_text {
    width: 100%;

    p {
      position: absolute;
      left: 50px;
    }

    .text_p {
      left: 100px;
      top: 25px;
    }
  }
  .border_square {
    height: 120px;
    width: 180px;
    border: 1px solid white;
    border-right: none;
  }
  .line {
    position: absolute;
    left: 180px;
    height: 30px;
    width: 1px;
    background-color: white;
  }
}

.personal_info {
  position: absolute;
  color: white;
  font-size: 16px;
  margin-top: 115px;
  padding-left: 30px;
  .span_container {
    width: 100%;
    text-align: flex-start;
    display: flex;
    align-items: flex-start;
    padding-top: 15px;
    padding-left: 20px;
  }
  .span_container > span {
    display: inline-block;
    width: 80px;
  }
  .span_container div {
    display: inline-block;
    /* flex: 1; */
  }
}

.block {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 200px;
  .left_card {
    position: absolute;
    left: -450px;
    text-align: end;
  }
  .row {
    height: 350px;
    background-image: url(../assets/logo.png);
    opacity: 0.09;
  }
  .el-card {
    height: 300px;
    width: 316px;
    border: 1px;
    background-color: transparent;
    overflow: hidden;
    color: white;
    transition: 0.3s;
  }
  .right_card {
    text-align: start;
  }
  .el-timeline {
    margin: auto;
    padding-left: 390px;
    margin-bottom: 100px;
  }
  .el-timeline li {
    height: 200px;
  }
  /deep/.el-timeline-item__node--normal {
    left: -50px;
    width: 100px;
    height: 100px;
    background-image: url(../assets/logo.png);
    background-size: 100px 100px;
    box-shadow: 0 0 10px rgb(253, 251, 251);
    border: 3px solid white;
  }
  /deep/.el-timeline-item__wrapper {
    margin-left: 60px;
    justify-content: center;
  }
}
</style>